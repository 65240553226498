<template>
  <div id="brands-view">
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-show="!isLoading">
      <vs-table hoverFlat ref="table" :data="data" search pagination :max-items="itemsPerPage">
        <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
          <div class="flex flex-wrap-reverse items-center">
            <router-link :to="{name: 'brandCreate'}" class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">{{ $t('add_new') }}</span>
            </router-link>
          </div>
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ orders.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : orders.length }} of {{ queriedItems }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage=4"><span>4</span></vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=10"><span>10</span></vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=15"><span>15</span></vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage=20"><span>20</span></vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <template slot="thead">
          <vs-th>{{ $t('fields.code') }}</vs-th>
          <vs-th>{{ $t('supplier') }}</vs-th>
          <vs-th>{{ $t('deliveryTime') }}</vs-th>
          <vs-th>{{ $t('note') }}</vs-th>
          <vs-th>{{ $t('status') }}</vs-th>
          <vs-th>{{ $t('fields.items') }}</vs-th>
          <vs-th>{{ $t('props.createdAt') }}</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="border-0 border-t border-solid border-grey-light pt-3">
            <vs-td :data="data[indextr].code">
              <span class="font-medium">#{{ data[indextr].code }}</span>
            </vs-td>
            <vs-td :data="data[indextr].supplier">
              {{ data[indextr].supplier.name }}
            </vs-td>
            <vs-td :data="data[indextr].delivery_time">
              {{ data[indextr].delivery_time }}
            </vs-td>
            <vs-td :data="data[indextr].note">
              {{ data[indextr].note }}
            </vs-td>
            <vs-td>
              <vs-chip :color="getOrderStatusColor(data[indextr].status)" class="product-order-status">{{ data[indextr].status | title }}</vs-chip>
            </vs-td>
            <vs-td :data="data[indextr].supplierorder_products.length">
              {{ data[indextr].supplierorder_products.length }}
            </vs-td>
            <vs-td :data="data[indextr].createdAt">
              {{ data[indextr].createdAt }}
            </vs-td>
            <template class="expand-order bg-primary" slot="expand">
              <div class="con-expand-orders w-full pl-3">
                <div class="con-btns-user flex items-center justify-between">
                  <div class="con-userx flex items-center justify-start">
                    <h3>{{ data[indextr].supplier.name }}</h3><span class="pt-1"> #{{ data[indextr].code }}</span> 
                  </div>
                  <div class="flex">
                    <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-success text-primary" @click.stop="showPage(tr.id)" />
                    <!-- <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary text-primary" class="ml-2" @click.stop="editData(tr.uuid)" /> -->
                    <!-- <feather-icon icon="RepeatIcon" svgClasses="w-5 h-5 hover:text-primary text-primary" class="ml-2" @click.stop="changeStatus(tr.uuid, tr.isActive)" /> -->
                  </div>
                </div>
                <div class="items-container border rounded-lg overflow-hidden border-grey-light border-solid mt-3">
                  <div class="vx-row w-full ml-0 border-b border-0 border-solid border-grey-light pt-2 px-3 pb-1" style="background: #ededed;">
                    <div class="w-1/12 font-medium">#</div>
                    <div class="w-1/12 font-medium">{{ $t('fields.code') }}</div>
                    <div class="w-8/12 font-medium">{{ $t('fields.itemName') }}</div>
                    <div class="w-2/12 font-medium">{{ $t('fields.amount') }}</div>
                  </div>
                  <div v-for="(item, idx) in data[indextr].supplierorder_products" :key="item.id" class="vx-row ml-0 p-3">
                    <div class="w-1/12">{{ idx + 1 }}</div>
                    <div class="w-1/12">{{ item.code }}</div>
                    <div class="w-8/12">{{ item[`name_${$i18n.locale}`] }}</div>
                    <div class="w-2/12 font-semibold">{{ item.Soproducts.quantity }}{{ $t('pcs') }}</div>
                  </div>
                </div>
              </div>
            </template>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      orders: [],
      itemsPerPage: 10,
      isLoading: true
    }
  },
  computed: {
    data () { return this.orders },
    currentPage () { return this.isLoading ? 0 : this.$refs.table.currentx },
    queriedItems () { return this.$refs.table ? this.$refs.table.queriedResults.length : this.orders.length }
  },
  async created () { await this.fetchData() },
  methods: {
    async fetchData () {
      this.$http.get('/suppliers/orders').then(response => {
        this.orders = response.data.data
        this.isLoading = false
      }).catch(() => { this.isLoading = false })
    },
    showPage (id) { this.$router.push(`/supplier-orders/${id}`) },
    deleteData (id) {
      const idx = this.categories.indexOf(id)
      this.categories.splice(idx, 1)
    },
    getOrderStatusColor (status) {
      if (status === 'on_hold')   return 'warning'
      if (status === 'pending')   return 'warning'
      if (status === 'delivered') return 'success'
      if (status === 'canceled')  return 'danger'
      return 'primary'
    }
  }
}
</script>

<style lang="scss">
#brands-view {
  .vs-con-table {

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }
  }
  .img {
    width: 60px;
    height: 60px;
    background-color: lightgray;
    border-radius: 4px;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
</style>
